import { app } from '@/main'

const TRANSLATION = {
  status: {
    pending: app.$t('global.status:pending'),
    failed: app.$t('global.status:failed'),
    finished: app.$t('global.status:finalized'),
    awaiting_payment: app.$t('global.status:awaiting_payment'),
    paid: app.$t('global.status:paid')
  }
}

export const getStatusClass = (status) => {
  switch (status) {
    case 'failed':
      return 'status-tag--inactive'
    case 'pending':
    case 'not-synced':
    case 'awaiting_payment':
      return 'status-tag--warning'
    case 'finished':
    case 'synced':
    case 'paid':
      return 'status-tag--active'
    default:
      return ''
  }
}

export const getStatusText = (status) => {
  return TRANSLATION.status[status]
}
